<template>
  <div class="preview-connection">
    <div class="zq-page-title-wrapper d-flex">
      <h3 class="zq-page-title">{{ formData.name }}</h3>
      <IconWithTooltip
        class="zq--header-tooltip"
        :text="descriptions.pageTitle"
      />
    </div>
    <CTabs
      class="zq--tabs"
      :active-tab="active"
      @update:activeTab="setActiveTransformerTab"
    >
      <CTab title="Rabbit MQ Connection">
        <RabbitMqConnectionDetails />
      </CTab>
      <CTab title="Transformer" v-if="formData && formData.transformerId">
        <TransformerDetails :transformerId="formData.transformerId"/>
      </CTab>
    </CTabs>
  </div>
</template>

<script>
import IconWithTooltip from '@/shared/UI/IconWithTooltip';
import { mapGetters } from 'vuex';
import { connections } from '@/config/descriptions/connections.json';
import { dateFormate } from '@/utils';
import RabbitMqConnectionDetails from '@/views/ziqni/settings/connections/details/RabbitMqConnectionDetails';
import TransformerDetails from '@/views/ziqni/settings/connections/details/TransformerDetails';
import { pageConfig } from '@/config';
import { connectionsTexts } from '@/config/pageTexts/connections.json';

export default {
  name: 'PreviewRabbitMQConnection',
  components: {
    IconWithTooltip,
    RabbitMqConnectionDetails,
    TransformerDetails,
  },
  data() {
    return {
      basicIsCollapsed: true,
      deleteModal: false,
      query: {},
      sortBy: pageConfig.sortBy,
      page: pageConfig.page,
      itemsPerPage: pageConfig.itemsPerPage,
      formData: {
        name: '',
        transformerId: '',
      },
      texts: {
        ...connectionsTexts,
      },
      transformer: {},
      options: {
        lineNumbers: true,
        readOnly: true,
        autoRefresh: true,
        itemsPerPage: pageConfig.itemsPerPage,
        query: {},
        sortBy: pageConfig.sortBy,
        page: pageConfig.page,
      },
      active: 0,
    };
  },
  computed: {
    ...mapGetters('rabbitMqConnections', [
      'rabbitMqConnection',
    ]),
    descriptions() {
      return {
        ...connections.preview,
      };
    },
  },
  watch: {
    rabbitMqConnection: {
      deep: true,
      handler: function (val) {
        if (val) {
          this.formData = val;
        }
      },
    },
  },
  methods: {
    setActiveTransformerTab(val) {
      this.active = val;
    },
    dateFormate,
  },
};
</script>

<style lang="scss">
.preview-connection {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.transformer-detail {
  height: 100%;
  flex: 2;
  background: var(--zq-sub-bg);
  .CodeMirror {
    height: 80%;
    border-radius: 12px;
  }
  .vue-codemirror {
    height: 100%;
  }
}
</style>
